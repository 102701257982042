import { notNil } from '@sixfold/typed-primitives';

interface UrlQueryParameter {
  key: string;
  value: string | undefined | null;
}

export function buildUrl(baseUrl: string, parameters: UrlQueryParameter[]) {
  const params = new URLSearchParams();

  for (const { key, value } of parameters) {
    if (notNil(value)) {
      params.set(key, value);
    }
  }

  const queryParams = params.toString();
  const queryParamsToAppend = queryParams !== '' ? `?${queryParams}` : '';
  return `${baseUrl}${queryParamsToAppend}`;
}
