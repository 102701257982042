import { Link } from '@sixfold/common-ui';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { MapPageFrame } from '../../components/map_page_frame';

export const UnsupportedBrowserPage: React.FC = () => {
  return (
    <MapPageFrame title="This browser is not supported" showHeader={false}>
      <Helmet>
        <title>Visibility Hub - This browser is not supported</title>
      </Helmet>
      <span>
        You are using an unsupported browser, you can see the list of supported browsers{' '}
        <Link
          href="https://www.transporeon.com/en/support/integration-system-requirements"
          target="_blank"
          rel="noopener noreferrer nofollow">
          here
        </Link>
        . Please upgrade your browser and try again.
      </span>
    </MapPageFrame>
  );
};
