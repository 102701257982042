import { Heading, Paragraph } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import React from 'react';

import { IconEmail } from '../../icons';
import { MapPageFrame } from '../../map_page_frame';

import styles from './EmailSent.module.css';

interface Props {
  email: string;
  footer?: React.ReactElement;
}

export const EmailSent: React.FC<Props> = ({ email, footer }) => {
  return (
    <MapPageFrame showHeader={false} footer={footer}>
      <div className={styles.loginSuccessContainer}>
        <IconEmail className={styles.emailSuccessIcon} />
        <Heading size="medium" type="display" className={styles.emailSuccessTitle}>
          <Localized id="login.email.success.title">Check your inbox</Localized>
        </Heading>
        <Paragraph size="large" className={styles.emailSuccessDetails}>
          <Localized id="login.email.success.details">
            An email with a login link has been sent to <b>{{ email }}</b>.
          </Localized>
        </Paragraph>
      </div>
    </MapPageFrame>
  );
};
