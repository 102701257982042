import { isNil } from '@sixfold/typed-primitives';

import { EmbedConfig, EmbedData } from '../../shared/types';

declare global {
  interface Window {
    __EMBED_DATA__?: EmbedData;
    __EMBED_CONFIG__?: EmbedConfig;
  }
}

export const getEmbedData = (): EmbedData => {
  const embedData = window.__EMBED_DATA__;
  if (isNil(embedData)) {
    console.error('Could not load embed data');
    return {};
  }

  return embedData;
};

export const getEmbedConfig = (): EmbedConfig | null => {
  const embedConfig = window.__EMBED_CONFIG__;
  if (isNil(embedConfig)) {
    console.error('Could not load embed config');
    return null;
  }

  return embedConfig;
};
