import { Route } from '@sixfold/app-framework';
import { compile } from 'path-to-regexp';

export type Home = Record<string, string | undefined>;
export const Home: Route<Home> = {
  routerPath: '/',
  generatePath: (params) => compile(Home.routerPath)(params),
  tracking: {
    name: 'Home',
  },
};

export type LandingPage = Record<string, string | undefined>;
export const LandingPage: Route<LandingPage> = {
  routerPath: '/landing_page',
  generatePath: (params) => compile(LandingPage.routerPath)(params),
  tracking: {
    name: 'Landing Page',
  },
};

export type SixfoldLoginNotice = Record<string, string | undefined>;
export const SixfoldLoginNotice: Route<SixfoldLoginNotice> = {
  routerPath: '/login_notice',
  generatePath: (params) => compile(SixfoldLoginNotice.routerPath)(params),
  tracking: {
    name: 'Login Notice',
  },
};

export type Login = Record<string, string | undefined>;
export const Login: Route<Login> = {
  routerPath: '/login',
  generatePath: (params) => compile(Login.routerPath)(params),
  tracking: {
    name: 'Login Form',
  },
};

export type LoginPage = Record<string, string | undefined>;
export const LoginPage: Route<LoginPage> = {
  routerPath: '/login_page',
  generatePath: (params) => compile(LoginPage.routerPath)(params),
  tracking: {
    name: 'Login Form Page',
  },
};

export type LoginViaSAML = Record<string, string | undefined>;
export const LoginViaSAML: Route<LoginViaSAML> = {
  routerPath: '/login/saml',
  generatePath: (params) => compile(LoginViaSAML.routerPath)(params),
  tracking: {
    name: 'Login via SAML',
  },
};

export type LoginViaSAMLReturn = Record<string, string | undefined>;
export const LoginViaSAMLReturn: Route<LoginViaSAMLReturn> = {
  // This is the SAML Service Provider Assert URL that can be found in the row auth_provider_saml_assert_url
  // in the company-service.platform table for each identity providers.
  // It is identical across all identity providers
  routerPath: '/login/saml/return',
  generatePath: (params) => compile(LoginViaSAMLReturn.routerPath)(params),
  tracking: {
    name: 'Login via SAML Return',
  },
};

export type ServiceProviderSAMLMetadata = Record<string, string | undefined>;
export const ServiceProviderSAMLMetadata: Route<ServiceProviderSAMLMetadata> = {
  routerPath: '/login/saml/metadata',
  generatePath: (params) => compile(ServiceProviderSAMLMetadata.routerPath)(params),
  tracking: {
    name: 'Login via SAML Metadata',
  },
};

export type TransporeonSignupPreAccept = Record<string, string | undefined>;
export const TransporeonSignupPreAccept: Route<TransporeonSignupPreAccept> = {
  routerPath: '/transporeon_signup',
  generatePath: (params) => compile(TransporeonSignupPreAccept.routerPath)(params),
  tracking: {
    name: 'Transporeon Signup',
  },
};

export type TransporeonSignupPreAcceptPage = Record<string, string | undefined>;
export const TransporeonSignupPreAcceptPage: Route<TransporeonSignupPreAcceptPage> = {
  routerPath: '/transporeon_signup_page',
  generatePath: (params) => compile(TransporeonSignupPreAcceptPage.routerPath)(params),
  tracking: {
    name: 'Transporeon Signup Page',
  },
};

export type TransporeonSignupReturn = Record<string, string | undefined>;
export const TransporeonSignupReturn: Route<TransporeonSignupReturn> = {
  routerPath: '/signup/transporeon/return',
  generatePath: (params) => compile(TransporeonSignupReturn.routerPath)(params),
  tracking: {
    name: 'Transporeon Signup Return',
  },
};

export type Token = Record<string, string | undefined>;
export const Token: Route<Token> = {
  routerPath: '/token',
  generatePath: (params) => compile(Token.routerPath)(params),
  tracking: {
    name: 'Token',
  },
};

export type GoToken = { token: string };
export const GoToken: Route<GoToken> = {
  routerPath: '/go/:token',
  generatePath: (params) => compile(GoToken.routerPath)(params),
  tracking: {
    name: 'Go Token',
  },
};

export type TokenLookup = { token?: string };
export const TokenLookup: Route<TokenLookup> = {
  routerPath: '/token_lookup/:token',
  generatePath: (params) => compile(TokenLookup.routerPath)(params),
};

export type RememberLoginCookie = Record<string, string | undefined>;
export const RememberLoginCookie: Route<RememberLoginCookie> = {
  routerPath: '/remember_login_cookie',
  generatePath: (params) => compile(RememberLoginCookie.routerPath)(params),
};

export type PlatformInvite = Record<string, string | undefined>;
export const PlatformInvite: Route<PlatformInvite> = {
  routerPath: '/invite',
  generatePath: (params) => compile(PlatformInvite.routerPath)(params),
  tracking: {
    name: 'Platform Invite',
  },
};

export type Unsubscribe = Record<string, string | undefined>;
export const Unsubscribe: Route<Unsubscribe> = {
  routerPath: '/unsubscribe',
  generatePath: (params) => compile(Unsubscribe.routerPath)(params),
  tracking: {
    name: 'Unsubscribe',
  },
};

export type EmailUnsubscribe = Record<string, string | undefined>;
export const EmailUnsubscribe: Route<EmailUnsubscribe> = {
  routerPath: '/email_unsubscribe',
  generatePath: (params) => compile(EmailUnsubscribe.routerPath)(params),
  tracking: {
    name: 'Email Unsubscribe',
  },
};

export type WithdrawnInvite = Record<string, string | undefined>;
export const WithdrawnInvite: Route<WithdrawnInvite> = {
  routerPath: '/withdrawn_invite',
  generatePath: (params) => compile(WithdrawnInvite.routerPath)(params),
  tracking: {
    name: 'Withdrawn Invite',
  },
};

export type ExpiredInvite = { companyName?: string };
export const ExpiredInvite: Route<ExpiredInvite> = {
  routerPath: '/expired_invite/:companyName',
  generatePath: (params) => compile(ExpiredInvite.routerPath)(params),
  tracking: {
    name: 'Expired Invite',
  },
};

export type LoginToken = { token: string };
export const LoginToken: Route<LoginToken> = {
  routerPath: '/login/:token',
  generatePath: (params) => compile(LoginToken.routerPath)(params),
  tracking: {
    name: 'Login Token',
  },
};

export type CompanyPicker = Record<string, string | undefined>;
export const CompanyPicker: Route<CompanyPicker> = {
  routerPath: '/company',
  generatePath: (params) => compile(CompanyPicker.routerPath)(params),
  tracking: {
    name: 'Company Picker',
  },
};

export type CompanyPickerPage = Record<string, string | undefined>;
export const CompanyPickerPage: Route<CompanyPickerPage> = {
  routerPath: '/company_picker',
  generatePath: (params) => compile(CompanyPickerPage.routerPath)(params),
  tracking: {
    name: 'Company Picker Page',
  },
};

export type CompanyPickerPicked = { company_id: string };
export const CompanyPickerPicked: Route<CompanyPickerPicked> = {
  routerPath: '/company/:company_id',
  generatePath: (params) => compile(CompanyPickerPicked.routerPath)(params),
  tracking: {
    name: 'Company Picker Picked',
  },
};

export type Redirect = Record<string, string | undefined>;
export const Redirect: Route<Redirect> = {
  routerPath: '/redirect',
  generatePath: (params) => compile(Redirect.routerPath)(params),
  tracking: {
    name: 'Redirect',
  },
};

export type UnsupportedBrowser = Record<string, string | undefined>;
export const UnsupportedBrowser: Route<UnsupportedBrowser> = {
  routerPath: '/unsupported_browser',
  generatePath: (params) => compile(UnsupportedBrowser.routerPath)(params),
  tracking: {
    name: 'Unsupported Browser',
  },
};
