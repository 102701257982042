import { ErrorBoundary as PrimitiveErrorBoundary } from '@sixfold/app-utils';
import * as React from 'react';

import { ErrorType, ErrorView } from '../error_view';

export const ErrorBoundary: React.FunctionComponent<React.PropsWithChildren<{ className?: string }>> = ({
  children,
}) => {
  const renderError = React.useCallback(() => <ErrorView error={{ type: ErrorType.GENERIC_ERROR }} />, []);

  return <PrimitiveErrorBoundary renderError={renderError}>{children}</PrimitiveErrorBoundary>;
};
