import { Button } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { useLocation } from 'react-router-dom';

import * as Routes from '../../lib/routes';
import { buildRedirectSearchParams, navigateToLocationWithServerSideRendering } from '../../lib/util';

interface Props {
  companyId: string | undefined;
}

export const NavigateButton: React.FC<Props> = ({ companyId }: Props) => {
  const [isNavigating, setIsNavigating] = React.useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get('redirectUrl') ?? undefined;
  const referralToken = query.get('referralToken');
  const signupReferenceId = query.get('signupReferenceId');

  React.useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        setIsNavigating(false);
        console.log('navigated');
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  return (
    <Button
      loading={isNavigating}
      size="medium"
      kind="primary"
      onClick={() => {
        setIsNavigating(true);
        const companyPickedRoute = Routes.CompanyPickerPicked.generatePath({
          company_id: companyId !== undefined ? companyId : '',
        });

        if (referralToken !== undefined || signupReferenceId !== undefined) {
          const searchParams = buildRedirectSearchParams(redirectUrl);

          if (notNil(referralToken)) {
            searchParams.set('referral_token', referralToken);
          }

          if (notNil(signupReferenceId)) {
            searchParams.set('signup_reference_id', signupReferenceId);
          }
          setIsNavigating(false);
          return navigateToLocationWithServerSideRendering(`${companyPickedRoute}?${searchParams.toString()}`);
        }

        setIsNavigating(false);
        navigateToLocationWithServerSideRendering(companyPickedRoute);
      }}>
      <Localized id="company.picker.button.goToSixfoldApp">Go to Visibility Hub</Localized>
    </Button>
  );
};
