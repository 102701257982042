import { SpinnerOverlay } from '@sixfold/common-ui';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ErrorType, ErrorView } from './components/error_view';
import { CompanyPickerContainer } from './containers/company_picker';
import { EmailUnsubscribe } from './containers/email_unsubscribe';
import { ExpiredInvite } from './containers/expired_invite';
import { Invite } from './containers/invite';
import { LandingPage } from './containers/landing_page';
import { LoginPage } from './containers/login';
import { RedirectContainer } from './containers/redirect';
import { TokenContainer } from './containers/token';
import { TransporeonSignup } from './containers/transporeon_signup';
import { UnsupportedBrowserPage } from './containers/unsupported-browser';
import { WithdrawnInvite } from './containers/withdrawn_invite';
import * as Routes from './lib/routes';
import './app_router.module.css';

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        path={Routes.TransporeonSignupPreAcceptPage.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <TransporeonSignup />
          </Suspense>
        )}
      />
      <Route
        path={Routes.Redirect.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <RedirectContainer />
          </Suspense>
        )}
      />
      <Route
        path={Routes.Token.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <TokenContainer />
          </Suspense>
        )}
      />
      <Route
        path={Routes.PlatformInvite.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <Invite />
          </Suspense>
        )}
      />
      <Route
        path={Routes.CompanyPickerPage.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <CompanyPickerContainer />
          </Suspense>
        )}
      />
      <Route
        path={Routes.LandingPage.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <LandingPage />
          </Suspense>
        )}
      />
      <Route
        path={Routes.LoginPage.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <LoginPage />
          </Suspense>
        )}
      />
      <Route
        path={Routes.EmailUnsubscribe.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <EmailUnsubscribe />
          </Suspense>
        )}
      />
      <Route
        path={Routes.WithdrawnInvite.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <WithdrawnInvite />
          </Suspense>
        )}
      />
      <Route
        path={Routes.ExpiredInvite.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <ExpiredInvite />
          </Suspense>
        )}
      />
      <Route
        path={Routes.UnsupportedBrowser.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <UnsupportedBrowserPage />
          </Suspense>
        )}
      />
      <Route
        path="*"
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <ErrorView error={{ type: ErrorType.PAGE_NOT_FOUND }} />
          </Suspense>
        )}
      />
    </Switch>
  );
};
