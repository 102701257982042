import { useAnalytics } from '@sixfold/app-analytics-framework';
import { Button } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import classnames from 'classnames';
import React from 'react';

import { RememberLoginDetails } from '../../../lib/remember_login_types';
import { MapPageFrame } from '../../map_page_frame';

import styles from './RememberLogin.module.css';

interface Props {
  isEmailLoginLoading: boolean;
  rememberLoginDetails: RememberLoginDetails;
  onTransporeonLoginClick: () => void;
  onSendLoginEmailClick: (email: string) => void;
  onManualLoginClick: () => void;
  footer?: React.ReactElement;
}

export const RememberLogin: React.FC<Props> = ({
  isEmailLoginLoading,
  rememberLoginDetails,
  onTransporeonLoginClick,
  onSendLoginEmailClick,
  onManualLoginClick,
  footer,
}) => {
  const analytics = useAnalytics();

  return (
    <MapPageFrame
      showHeader={true}
      title={<Localized defaultValue="Hello again!" id="login.rememberLogin.title" />}
      footer={footer}>
      <div className={styles.rememberLoginContainer}>
        {rememberLoginDetails.type === 'email' && (
          <>
            <span className={styles.typeDescription}>
              <Localized id="login.rememberLogin.lastEmailLoginDescription">
                You last logged in with <b>{{ email: rememberLoginDetails.email }}</b>
              </Localized>
            </span>
            <Button
              kind="primary"
              size="medium"
              type="submit"
              loading={isEmailLoginLoading}
              onClick={() => {
                analytics?.track('Clicked log in with email (remembered login)');
                onSendLoginEmailClick(rememberLoginDetails.email);
              }}
              className={classnames(styles.button, styles.buttonEmailLogin)}>
              <Localized id="login.rememberLogin.button.loginWithEmail">Log in with this email</Localized>
            </Button>
          </>
        )}
        {rememberLoginDetails.type === 'transporeon' && (
          <>
            <span className={styles.typeDescription}>
              <Localized id="login.rememberLogin.lastTransporeonLoginDescription">
                You last logged in with your Transporeon account.
              </Localized>
              <br />
            </span>
            <Button
              kind="primary"
              size="medium"
              type="submit"
              loading={isEmailLoginLoading}
              onClick={() => {
                analytics?.track('Clicked log in with transporeon (remembered login)');
                onTransporeonLoginClick();
              }}
              className={classnames(styles.button, styles.buttonTpLogin)}>
              <Localized id="login.rememberLogin.button.loginWithTransporeon">Log in with Transporeon</Localized>
            </Button>
          </>
        )}
        <div className={styles.loginSpacer}>
          <Localized id="login.spacer">or</Localized>
        </div>
        <Button
          kind="neutral"
          size="medium"
          className={classnames(styles.button, styles.buttonDifferentLogin)}
          onClick={() => {
            analytics?.track('Clicked log in with different account (remembered login)');
            onManualLoginClick();
          }}>
          <Localized id="login.rememberLogin.button.loginWithDifferent">Log in with a different account</Localized>
        </Button>
      </div>
    </MapPageFrame>
  );
};
