import { Logo } from '@sixfold/common-ui';
import * as React from 'react';

import { LanguagePicker } from '../language_picker';

import styles from './Header.module.css';

export const Header = () => {
  return (
    <header className={styles.header}>
      <Logo claim={true} />
      <LanguagePicker />
    </header>
  );
};
