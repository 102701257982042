import { useAnalytics } from '@sixfold/app-analytics-framework';
import {
  Button,
  LanguagePicker as CommonUiLanguagePicker,
  LanguagePickerItem,
  VisuallyHidden,
  Text,
} from '@sixfold/common-ui';
import { useLanguage, i18n, Localized } from '@sixfold/localization-component';
import { availableLanguages } from '@sixfold/login-web-strings';
import * as React from 'react';

export const supportedLanguages: { [key in (typeof availableLanguages)[number]]: string } = {
  bg: 'Български',
  cs: 'Čeština',
  de: 'Deutsch',
  en: 'English',
  'en-US': 'English (US)',
  es: 'Español',
  fr: 'Français',
  hr: 'Hrvatski',
  it: 'Italiano',
  hu: 'Magyar',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'Pусский',
  sk: 'Slovenčina',
  fi: 'Suomi',
  sv: 'Svenska',
  tr: 'Türkçe',
  uk: 'Українська',
};

export const LanguagePicker: React.FunctionComponent = () => {
  const analytics = useAnalytics();
  const currentLanguage = useLanguage();

  return (
    <CommonUiLanguagePicker
      label={<Localized id="component.languagepicker.language.title">Language</Localized>}
      trigger={({ label, selectedItem }) => {
        return (
          <Button iconStart="small-language" kind="ghost" size="small">
            <VisuallyHidden>{label}: </VisuallyHidden>
            <Text weight="regular">{selectedItem?.label}</Text>
          </Button>
        );
      }}
      value={currentLanguage}
      onChange={async (lang) => {
        if (i18n.isInitialized) {
          await i18n.changeLanguage(lang);
        }

        analytics?.track('Language changed', { previousLanguage: currentLanguage, currentLanguage: lang });
      }}>
      {Object.keys(supportedLanguages).map((lang) => {
        return <LanguagePickerItem key={lang} value={lang} label={supportedLanguages[lang]} />;
      })}
    </CommonUiLanguagePicker>
  );
};
