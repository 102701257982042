import { useAnalytics } from '@sixfold/app-analytics-framework';
import { useErrorHandler } from '@sixfold/app-utils';
import { NestedError, notNil } from '@sixfold/typed-primitives';
import { request } from 'graphql-request';
import * as React from 'react';

import { ErrorType, ErrorView } from '../error_view';
import { EmailSent } from './email_sent/email_sent';
import { Login } from './login/login';
import { RememberLogin } from './remember_login/remember_login';
import { RememberLoginDetails } from '../../lib/remember_login_types';
import { fetchJSON } from '../../lib/util';
import { Footer } from '../footer/footer';
import { SixfoldLoginNoticePage } from '../sixfold_login_notice';

interface Props {
  redirectURI?: string;
  onTransporeonLoginClick: () => void;
  showLoginWarning: boolean;
  rememberLoginDetails?: RememberLoginDetails;
}

type LoginViaEmailState =
  | {
      emailSent: false;
    }
  | {
      emailSent: true;
      email: string;
    }
  | LoginViaEmailLoginNoticeState
  | LoginViaEmailFailureState;

type LoginViaEmailLoginNoticeState = {
  emailSent: false;
  email: string;
  showingLoginNotice: true;
};

type LoginViaEmailFailureState = {
  emailSent: false;
  email: string;
  errorMessage: string;
};

const isLoginViaEmailError = (sendingState: LoginViaEmailState): sendingState is LoginViaEmailFailureState => {
  return (sendingState as LoginViaEmailFailureState).errorMessage !== undefined;
};

const isLoginViaEmailLoginNoticeState = (
  sendingState: LoginViaEmailState,
): sendingState is LoginViaEmailLoginNoticeState => {
  return (sendingState as LoginViaEmailLoginNoticeState).showingLoginNotice;
};

const sendLoginEmailMutation = `
  mutation SendLoginEmail($email: String!, $redirectURI: String, $isWhitelisted: Boolean) {
    sendLoginEmail(email: $email, redirectURI: $redirectURI, isWhitelisted: $isWhitelisted)
  }
`;

export const LoginView: React.FC<Props> = ({
  redirectURI,
  onTransporeonLoginClick,
  showLoginWarning,
  rememberLoginDetails,
}) => {
  const analytics = useAnalytics();
  const handleError = useErrorHandler();
  const [currentRememberLoginDetails, setCurrentRememberLoginDetails] = React.useState<
    RememberLoginDetails | undefined
  >(rememberLoginDetails);
  const [isEmailLoginLoading, setIsEmailLoginLoading] = React.useState(false);
  const [loginViaEmail, setLoginViaEmail] = React.useState<LoginViaEmailState>({
    emailSent: false,
  });

  const handleSendLoginEmail = React.useCallback(
    async (email: string, isWhitelisted: boolean) => {
      setIsEmailLoginLoading(true);
      analytics?.track('Sent request to login via email');

      try {
        const { sendLoginEmail: isLoginEmailSent } = await request<{ sendLoginEmail: boolean }>(
          '/public/graphql',
          sendLoginEmailMutation,
          {
            email,
            redirectURI,
            isWhitelisted,
          },
        );

        setLoginViaEmail(
          isLoginEmailSent
            ? {
                emailSent: true,
                email,
              }
            : {
                emailSent: false,
                email,
                errorMessage: 'Email not sent',
              },
        );
      } catch (error) {
        const e = error as { response?: { errors?: Error[] }; message?: string };
        const concatenatedErrorMessages =
          e.response?.errors?.map((error: { message: string }) => error.message).join(',') ?? e.message;

        setLoginViaEmail({
          emailSent: false,
          email,
          errorMessage: concatenatedErrorMessages,
        });

        const capturedError = new NestedError('Login via email failed', error, { metadata: { email } });
        handleError?.captureError(capturedError);
      } finally {
        setIsEmailLoginLoading(false);
      }
    },
    [redirectURI, analytics, handleError],
  );

  const onSendLoginEmailClick = React.useCallback(
    async (email: string) => {
      let isWhitelisted = true;
      try {
        const response = await fetchJSON<{ isWhitelisted: boolean }>(`/check_whitelist?email=${email}`);
        isWhitelisted = response.isWhitelisted;
      } catch (error) {
        const capturedError = new NestedError('Whitelist check failed', error, { metadata: { email } });
        handleError?.captureError(capturedError);
      }
      if (isWhitelisted) {
        await handleSendLoginEmail(email, true);
        return;
      }

      setLoginViaEmail({
        emailSent: false,
        email,
        showingLoginNotice: true,
      });
    },
    [handleSendLoginEmail, handleError],
  );

  const footer = React.useMemo(() => <Footer />, []);

  if (isLoginViaEmailError(loginViaEmail)) {
    return (
      <ErrorView
        error={
          loginViaEmail.errorMessage !== null && loginViaEmail.errorMessage.match(/User not found/g) !== null
            ? {
                type: ErrorType.USER_NOT_FOUND,
                email: loginViaEmail.email,
              }
            : {
                type: ErrorType.GENERIC_ERROR,
                message: loginViaEmail.errorMessage,
              }
        }
      />
    );
  }

  if (loginViaEmail.emailSent) {
    return <EmailSent email={loginViaEmail.email} footer={footer} />;
  }

  if (isLoginViaEmailLoginNoticeState(loginViaEmail)) {
    return <SixfoldLoginNoticePage onTransporeonLogin={onTransporeonLoginClick} />;
  }

  if (notNil(currentRememberLoginDetails) && currentRememberLoginDetails?.type) {
    return (
      <RememberLogin
        isEmailLoginLoading={isEmailLoginLoading}
        onTransporeonLoginClick={onTransporeonLoginClick}
        onSendLoginEmailClick={onSendLoginEmailClick}
        onManualLoginClick={() => {
          setCurrentRememberLoginDetails(undefined);
        }}
        rememberLoginDetails={currentRememberLoginDetails}
        footer={footer}
      />
    );
  }

  return (
    <Login
      isEmailLoginLoading={isEmailLoginLoading}
      onSendLoginEmailClick={onSendLoginEmailClick}
      showLoginWarning={showLoginWarning}
      footer={footer}
    />
  );
};
