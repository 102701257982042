import Cookies from 'js-cookie';

import { getEmbedConfig } from '../data';

const getTrackingCookieName = () => getEmbedConfig()?.tracking_cookie_name;
const getCookieDomain = () => getEmbedConfig()?.cookie_domain;

export const getIsConsentGiven = () => readConsentCookie() === 'ok';

function readConsentCookie(): 'ok' | 'nok' | undefined {
  const cookieName = getTrackingCookieName();
  const value = cookieName !== undefined ? Cookies.get(cookieName) : undefined;
  if (value === 'ok' || value === 'nok') {
    return value;
  }

  return undefined;
}

export function setConsentCookie(isConsentGiven: boolean) {
  const cookieDomain = getCookieDomain();
  const cookieName = getTrackingCookieName();
  const value = isConsentGiven ? 'ok' : 'nok';
  if (cookieName !== undefined) {
    Cookies.set(cookieName, value, { domain: cookieDomain, expires: 365 * 10, SameSite: 'none', secure: true });
  }
}
