import { isNil } from '@sixfold/typed-primitives';
import * as cookie from 'cookie';
import * as React from 'react';

import { setConsentCookie } from './cookie_consent';
import { getEmbedConfig } from '../data';

export const isTrackingCookieAllowed = () => {
  const config = getEmbedConfig();

  const trackingCookieName = config?.tracking_cookie_name;
  if (isNil(trackingCookieName)) {
    return false;
  }

  const cookies = typeof window !== 'undefined' ? cookie.parse(document.cookie) : {};

  return cookies[trackingCookieName] === 'ok';
};

export const TrackingContext = React.createContext<{
  isTrackingAllowed: boolean;
  setTracking: (newState: boolean) => boolean;
}>({
  isTrackingAllowed: true,
  setTracking: (state) => {
    setConsentCookie(state);
    return state;
  },
});

export const TrackingProvider = TrackingContext.Provider;
