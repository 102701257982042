export const navigateToLocationWithServerSideRendering = (url: string) => {
  // In-between solution (before we introduce an endpoint to check for auth'ness)
  // We force every navigation to go through the server

  window.location.href = url;
};

export const buildRedirectSearchParams = (redirectTarget: string | undefined) => {
  if (redirectTarget === undefined) {
    return new URLSearchParams();
  }

  return new URLSearchParams({ next: redirectTarget });
};

export const fetchJSON = async <T>(url: string): Promise<T> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch JSON from ${url}`);
  }
  return (await response.json()) as T;
};
