import { Icon, Pill } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import classnames from 'classnames';
import * as React from 'react';

import styles from './CompanyPicker.module.css';

interface Company {
  company_id: string;
  company_name: string;
  is_shipper: boolean;
  is_carrier: boolean;
}

interface Props {
  companies: Company[];
  selectedCompany?: Company;
  onChange: (company: Company) => void;
}

export const CompanyPicker: React.FC<React.PropsWithChildren<Props>> = ({
  companies,
  selectedCompany,
  onChange,
  children,
}) => (
  <div className={styles.picker}>
    <ul className={styles.list}>
      {companies.map(({ company_id, company_name, is_shipper, is_carrier }) => {
        const isSelected = selectedCompany !== undefined && selectedCompany.company_id === company_id;

        return (
          <li
            key={company_id}
            className={classnames({
              [styles.selected]: isSelected,
            })}
            onClick={() => onChange({ company_id, company_name, is_shipper, is_carrier })}>
            <span>{company_name}</span>
            <div className={styles.roles}>
              {(is_shipper || is_carrier) && (
                <Pill className={styles.roles}>
                  {is_shipper && <Localized id="component.company_picker.roles.shipper">Shipper</Localized>}
                  {is_carrier && is_shipper && <span className={styles.separator}>{' · '}</span>}
                  {is_carrier && <Localized id="component.company_picker.roles.carrier">Carrier</Localized>}
                </Pill>
              )}
            </div>
            {isSelected ? <Icon name="small-check" /> : null}
          </li>
        );
      })}
    </ul>
    {children}
  </div>
);
