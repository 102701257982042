import { useTheme } from '@sixfold/common-ui';
import * as React from 'react';

interface Props {
  className?: string;
}

export const IconEmail: React.FC<Props> = ({ className }) => {
  const { variables } = useTheme();

  return (
    <svg width="96" height="96" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        clipRule="evenodd"
        d="M94 8L3.726 45.292a2.8 2.8 0 0 0 .32 5.272L38 60 94 8z"
        stroke={variables?.color.highlight.graphic.subtle}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M94 8L78.6 81.144a3.6 3.6 0 0 1-5.504 2.248L38 60 94 8z"
        stroke={variables?.color.highlight.graphic.subtle}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 60v25.92a2.076 2.076 0 0 0 3.868 1.044l10.244-17.56"
        stroke={variables?.color.highlight.graphic.subtle}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
