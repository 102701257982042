import { Button, ButtonLink, Link, Paragraph, Stack } from '@sixfold/common-ui';
import { Localized, useLanguage, useLocalizations } from '@sixfold/localization-component';
import React from 'react';

import { MapPageFrame } from '../map_page_frame';

interface Props {
  onTransporeonLogin: () => void;
}

export const SixfoldLoginNoticePage: React.FC<Props> = ({ onTransporeonLogin }) => {
  const language = useLanguage();
  const shutdownDate = new Date(2024, 7, 15).toLocaleDateString(language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const localizations = useLocalizations(
    {
      'loginNotice.title.afterShutdown': 'Access Visibility Hub using your Transporeon account',
      'loginNotice.button.contactCustomerSupport': 'Contact Customer Support',
      'loginNotice.button.loginWithTransporeon': 'Log in with Transporeon',
    },
    {
      date: shutdownDate,
    },
  );

  const ActionButtons: React.FunctionComponent = () => (
    <Stack direction="column" gap="medium">
      <ButtonLink
        fullWidth
        kind="primary"
        href="https://support.transporeon.com/servicedesk/customer/portal/16/create/474"
        target="_blank"
        rel="noopener noreferrer nofollow">
        {localizations['loginNotice.button.contactCustomerSupport']}
      </ButtonLink>
      <Button fullWidth kind="neutral" onClick={onTransporeonLogin}>
        {localizations['loginNotice.button.loginWithTransporeon']}
      </Button>
    </Stack>
  );

  return (
    <MapPageFrame showHeader={true} title={localizations['loginNotice.title.afterShutdown']}>
      <Stack gap="small">
        <Paragraph>
          <Localized id="loginNotice.para1.afterShutdown">
            Your email login has been deactivated as of August 15, 2024.
          </Localized>
        </Paragraph>
        <Paragraph>
          <Localized id="loginNotice.para2.afterShutdown">
            To access Visibility Hub via Transporeon Web, please log in to{' '}
            <Link href="https://login.transporeon.com" target="_blank" rel="noopener noreferrer nofollow">
              Transporeon
            </Link>{' '}
            with your Transporeon account and select "Visibility".
          </Localized>
        </Paragraph>
        <Paragraph>
          <Localized id="loginNotice.para3.afterShutdown">For assistance, contact our Customer Support team.</Localized>
        </Paragraph>
        <Paragraph>
          <Localized id="loginNotice.para4.afterShutdown">
            We apologize for any inconvenience and appreciate your understanding.
          </Localized>
        </Paragraph>
      </Stack>
      <br />
      <ActionButtons />
    </MapPageFrame>
  );
};
