import { Button, Link } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { getQueryParameters } from '@sixfold/query-string';
import classnames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { buildUrl } from '../../lib/build_url';
import * as Routes from '../../lib/routes';
import { MapPageFrame } from '../map_page_frame';

import styles from './ErrorView.module.css';

interface ErrorPageFrameProps {
  title: React.ReactNode;
  loginButtonText: React.ReactNode;
}

export const ErrorPageFrame: React.FC<React.PropsWithChildren<ErrorPageFrameProps>> = ({
  title,
  children,
  loginButtonText,
}) => {
  const getLoginHref = () => {
    const searchParams = getQueryParameters(window.location.search);

    return {
      loginURL: Routes.LandingPage.generatePath({}),
      loginURLQueryParameters: Object.keys(searchParams).map((key) => ({
        key: key.toString(),
        value: searchParams[key],
      })),
    };
  };

  const history = useHistory();
  const navigateToLogin = React.useCallback(async () => {
    const { loginURL, loginURLQueryParameters } = getLoginHref();
    const loginRoute = buildUrl(loginURL, loginURLQueryParameters);

    history.push(loginRoute);
  }, [history]);

  const contactSupport = () => {
    window.open('https://support.transporeon.com/');
  };

  return (
    <MapPageFrame title={title} className={{ content: styles.content }}>
      {children}
      <span className={classnames(styles.small, styles.text)}>
        <Localized id="login.error.contactSupport">
          If you’re unable to log in, please contact{' '}
          <Link href="https://support.transporeon.com" target="_blank" rel="noopener noreferrer nofollow">
            our support.
          </Link>
        </Localized>
      </span>
      <Button size="medium" kind="primary" onClick={navigateToLogin}>
        {loginButtonText}
      </Button>
      <Button kind="neutral" size="medium" onClick={contactSupport}>
        <Localized id="signup.error.companyExists.button.contactSupport">Contact support</Localized>
      </Button>
    </MapPageFrame>
  );
};
