import { NestedError } from '@sixfold/typed-primitives';
import * as React from 'react';

import * as Routes from './routes';
import { fetchJSON } from './util';

export function useTokenData<T>(token?: string) {
  const [data, setData] = React.useState<T | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchJSON<T>(Routes.TokenLookup.generatePath({ token }));
        setData(response);
      } catch (error) {
        throw new NestedError('Failed to fetch token data', error, { metadata: { token } });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().catch((e) => console.error(e));
  }, [token]);

  return { data, isLoading };
}
