import { Link } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

export const Footer: React.FunctionComponent = () => (
  <Localized id="login.footer.needHelp.button">
    Need help?{' '}
    <Link
      href="https://support.transporeon.com/servicedesk/customer/portal/16/create/474"
      target="_blank"
      rel="noopener noreferrer nofollow">
      Contact us.
    </Link>
  </Localized>
);
