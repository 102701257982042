import { Heading } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import classnames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import * as Routes from '../../lib/routes';
import { Header } from '../header';

import styles from './MapPageFrame.module.css';

export const MapPageFrame: React.FunctionComponent<
  React.PropsWithChildren<{
    title?: React.ReactNode;
    footer?: React.ReactElement;
    showHeader?: boolean;
    className?: { content?: string; mapContainerClassName?: string };
    showLoginLink?: boolean;
  }>
> = ({ title, footer, children, className, showLoginLink, showHeader = true }) => {
  const contentClassName = className?.content;
  const mapContainerClassName = className?.mapContainerClassName;

  return (
    <div className={classnames(mapContainerClassName, styles.mapPageFrame)}>
      <div>{/* spacer for flexbox – other approaches are broken in IE11 */}</div>
      <div className={styles.container}>
        <main>
          {showHeader && <Header />}
          <div
            className={classnames(styles.content, contentClassName, {
              [styles.withoutHeader]: !showHeader,
            })}>
            {title && (
              <Heading size="xlarge" className={styles.title}>
                {title}
              </Heading>
            )}
            {children}
          </div>
          {footer && <footer className={styles.footer}>{footer}</footer>}
        </main>
      </div>
      {showLoginLink && (
        <div className={styles.loginText}>
          <Localized id="login.lookingforemaillogin.new">
            Looking for email login? <NavLink to={Routes.LoginPage.generatePath({})}>Log in here</NavLink>
          </Localized>
        </div>
      )}
    </div>
  );
};
